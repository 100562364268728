// @ts-nocheck
import { META_PIXEL_ID } from 'src/env.json';

declare global {
  interface Window {
    fbq: (action: string, event: string, data?: Record<string, any>) => void;
  }
}

(() => {
  if (!META_PIXEL_ID) {
    return;
  }

  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };

    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    t.onerror = (e) => console.error('Failed to load Meta Pixel', e);
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', META_PIXEL_ID);
  fbq('track', 'PageView');
})();

export {};
